<template>
  <div>
    <div v-if="isLoading" class="my-3">
      <b-spinner class="text-primary d-block m-auto" />
    </div>
    <div v-else>
      <ProfileProgressBar
        v-if="member"
        :member-info="member"
        :settings-view="true"
      />
      <div v-if="showLinkedinCheckbox" style="display: flex;">
        <b-form-checkbox
          v-model="completeProfileWithLinkedin"
          class="mb-1 ml-50"
        />
        {{ 'Complete Profile With Linkedin' }}
      </div>
      <div>
        <!-- Links -->
        <Links :profile-links="profile.links" @save-links="handleSaveLinks" />

        <!-- Mails -->
        <Mails :emails="member.emails" @save-mails="handleSaveMails" />

        <!-- Phones -->
        <Phones 
          :phones="member.phones" 
          @save-phones="handleSavePhones" 
        />

        <!-- Locations -->
        <b-card>
          <div class="d-flex flex-wrap justify-content-between mt-2">
            <h4>{{ $t("backoffice.settings.subtitle-locations") }}</h4>
            <b-button
              variant="primary"
              type="submit"
              :disabled="isSaving"
              @click="onSubmit"
            >
              {{ $t("form.actions.save") }}
            </b-button>
          </div>
          <validation-provider
            v-slot="validationContext"
            :name="$t('organizations.edit.locations.name')"
          >
            <b-form-group
              :label="$t('backoffice.settings.location-label')"
              label-for="profile-location"
            >
              <PlacesMapSelect
                v-model="profile.locations"
                :placeholder="$t('organizations.edit.locations.placeholder')"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue';
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'MyContactData',

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ProfileProgressBar: () => import('../ProfileProgressBar.vue' /* webpackChunkName: "ProfileProgressBar" */),
    PlainEditor: () => import('@core/components/editor/PlainEditor.vue' /* webpackChunkName: "PlainEditor" */),
    Locale: () => import('@core/layouts/components/app-navbar/components/Locale.vue' /* webpackChunkName: "Locale" */),
    Links: () => import('@/views/myself/components/contact/Links.vue' /* webpackChunkName: "Links" */),
    Phones: () => import('@/views/myself/components/contact/Phones.vue' /* webpackChunkName: "Phones" */),
    Mails: () => import('@/views/myself/components/contact/Mails.vue' /* webpackChunkName: "Mails" */),
    PlacesMapSelect: () => import('@core/components/places-map-input/PlacesMapSelect.vue' /* webpackChunkName: "PlacesMapSelect" */),
  },

  directives: { BTooltip: VBTooltip },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      member: null,
      required,
      max,
      isStaff: false,
      send: false,
      profile: {
        avatarURL: null,
        backgroundURL: null,
        name: '',
        surname: '',
        headline: '',
        summary: '',
        links: [],
        phones: [],
        telephones: [],
        address: [],
        organizations: null,
        locations: [],
        openMeetings: true,
        openChat: true,
        showMe: true,
        showEmail: true,
      },
      isSaving: false,
      isLoading: true,
      enabledOrganizationApp: false,
      mainOrganizations: null,
      openMeetings: true,
      openChat: true,
      showMe: true,
      memberType: {},
      types: [],
      newLocale: null,
      selectedLanguage: this.currentLocale,
      completeProfileWithLinkedin: false,
      showLinkedinCheckbox: false
    };
  },

  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  
  computed: {
    ...mapGetters([
      'loggedMember',
      'parentCollective',
      'currentCollective',
      'mainCollective',
    ]),
    communitySlug() {
      return this.$route.params.communityId;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
  },
  watch: {
    member(value) {
      return value;
    },
  },
  async created() {
    this.isLoading = true;
    this.showLinkedinCheckbox = this.mainSpace.key === "7c23efd0-9d5e-11ed-804a-650ff09867b0" ? true : false;
    this.$emit('isLoading', this.isLoading);
    await this.fetchMember(this.loggedMember.username);
    this.memberType = this.member?.type ? this.member?.type : this.types.filter((item) => item.name.en === 'Member')[0];
    this.resetForm();
    this.isLoading = false;
    this.$emit('isLoading', this.isLoading);
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-create-organizations');
    },
    async getLoggedMember() {
      // await this.installedMainApps();
      const response = await this.$store.$service[Service.BackendClient].get(
        '/communityMembers',
        {
          params: {
            communitySlug:
              this.$store.getters.mainCollective?.slug
              || this.communitySlug,
            username: this.loggedMember.username,
            isForProfile: true,
          },
        },
      );
      this.mainOrganizations = response.data.data[0]?.organizations;
      this.profile.organizations = this.mainOrganizations?.map((item) => item);
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    resetForm() {
      this.profile = { ...this.member };
      console.log('this.profile', this.profile);
      console.log('this.member', this.member);
    },
    async fetchMember(username) {
      try {
        const spaceSlug = this.$store.getters.mainCollective?.slug ?? this.currentCollective.slug;
        console.log('SpaceSlug:', spaceSlug);

        // TODO: use member store
        let response = await this.$store.$service[Service.BackendClient].get(
          '/communityMembers',
          {
            params: {
              communitySlug: spaceSlug,
              username,
              isForProfile: true,
            },
          },
        );
        //console.log('GlobalSettings fetchMember response1: ', response);
        //console.log('GlobalSettings fetchMember response.data.data[0]', response.data.data[0])

        const member = response.data.data[0];
        if (!member) {
          this.$router.push({
            name: 'error',
            params: {
              communityId: this.$route.params.communityId,
              error: '404',
            },
          });
          return;
        }
        this.member = member;
        console.log('We save the member data:', this.member);
      } catch (error) {
        this.hasErrors = true;
        console.log('error', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.load-failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async updateProfile() {
      return await this.$store.$service[Service.BackendClient].put(
        'members',
        {
          communityKey: this.currentCollective.key,
          memberKey: this.member.key,
          links: this.profile.links || [],
          phones: this.telephones,
          emails: this.address,
          locations: this.profile.locations,
          completeProfileWithLinkedin: this.completeProfileWithLinkedin,
        },
      );

    },
    async onSubmit() {
      try {
        const updateProfileResponse = await this.updateProfile();
        this.notifySuccess(this.$t('members.edit.success-message'));
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
      await this.fetchMember(this.loggedMember.username);
    },
    handleSaveLinks(links) {
      this.profile.links = links;
      this.onSubmit();
    },
    handleSavePhones(phones) {
      this.telephones = phones;
      this.onSubmit();
    },
    handleSaveMails(mails) {
      this.address = mails;
      this.onSubmit();
    },
    handleCreate() {
      this.getLoggedMember();
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.foto {
  max-height: 150px;
  max-width: 150px;
}
.banner {
  max-height: 200px;
  max-width: 500px;
}
.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.text-edit {
  height: 5rem !important;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
</style>
